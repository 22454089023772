import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import {
  useHistory
} from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TabContent,
  TabPane,
  Modal,
  ModalBody
} from "reactstrap";
import ReactQuill from 'react-quill';
const axios = require("axios");
import { authtoken } from "global.js";
import { apiUrl } from "../../constant";
import { toast } from "react-toastify";
// core components
import { gotraService } from "../../services/gotraService.js";
import moment from "moment";


const Index = (props) => {
  const history = useHistory();
  const [gotra, setGotra] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [setcreateformModalOpen, setcreateFormModalOpen] = useState(false);
  const get_gotra_data = async (pageNumber = 1, query = '') => {
    gotraService.getWithPagination(pageNumber, query).then((response) => {
      setGotra(response.data.data.data);
      setCurrentPage(response.data.data.currentPage);
      setLastPageData(response.data.data.totalPage);
    });
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_gotra_data(index);
  };

  const handlePreviousClick = () => {
    get_gotra_data(prev);
  };

  const handleNextClick = () => {
    get_gotra_data(next);
  };

  useEffect(() => {
    get_gotra_data()
  }, []);


  const initialStateData = {
    name: "",
    description: "",
    image: ""
  };

  const [inputFields, setInputfields] = useState(initialStateData);
  function onRespondClick() {
    var datasend = {
      "name": inputFields.name,
      "description": inputFields.description,
      "image": inputFields.image
    };
    axios({
      method: "post",
      url: `${apiUrl}/gotra/create`,
      data: datasend,
      headers: { Authorization: `${authtoken}` },
    })
      .then(
        function (response) {
          console.log(response)
          if (response.status == 200) {
            setInputfields(initialStateData)
            setcreateFormModalOpen(false)
            toast.success(response.data.message);
            get_gotra_data()
          } else {
            toast.error(response.data.message);
          }
        },
        (error) => {
          return error.response;
        }
      )
      .catch(function (error) { });
  }
  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));

  const deleterow = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((deletdata) => {
        if (deletdata) {
          gotraService.deleteById(e).then((response) => {
            if (response?.data?.code == 200) {
              const newList = gotra.filter((item) => item._id !== e);
              setGotra(newList);
              toast.success('Deleted Successfully');
            } else {
              toast.error('Failed');
            }
          });
        }
      });

  };
  const handleclick = (e) => {
    history.push({
      pathname: 'editgotra/' + e,
    });
  }

  const handleTreeClick = (e) => {
    history.push({
      pathname: 'treegotra/' + e,
    });
  }

  const [tooltipVisibility, setTooltipVisibility] = useState({});

  const toggleTooltip = (id) => {
    setTooltipVisibility((prevVisibility) => ({
      ...prevVisibility,
      [id]: !prevVisibility[id],
    }));
  };

  const renderTooltip = (id, text) => {
    return tooltipVisibility[id] && <div className="custom-tooltip">{text}</div>;
  };

  const ellipsisStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '10em', // Adjust the width as needed
    display: 'inline-block', // Ensures that the ellipsis works in inline contexts
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}

      <Container className="mt--7" fluid>

        <Row>

          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <div className="align-items-center row">

                  <Col lg="8" md="8" sm="8">
                    <h3 className="mb-0">Gotra</h3>
                  </Col>
                  <div className="text-right col-4"> <button className="btn btn-sm" style={{ backgroundColor: "#5e72e4", color: "#fff  " }} onClick={() => setcreateFormModalOpen(true)} > Create Gotra</button></div>
                </div>
              </CardHeader>
              <CardBody>
                {/* <FormGroup>
                  <Row>
                    <Col lg="12" md="8" sm="8">
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup> */}
                <TabContent activeTab={"tabs1"}>
                  <TabPane tabId={"tabs1"}>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Name </th>
                          <th scope="col">Image</th>
                          <th scope="col">Description</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gotra.length > 0  ? (
                          gotra.map((trans, i) => (
                            <tr key={i}  >
                              <th>{trans?.name}</th>
                              <th>{trans?.image}</th>
                              <th style={ellipsisStyle}>{trans?.description}</th>

                              <td> <div className="tooltip-container">
                                <Button
                                  className="btn btn-sm btn-success tooltip-button"
                                  onClick={() => handleclick(trans._id)}
                                  // onMouseEnter={() => toggleTooltip(trans._id)}
                                  // onMouseLeave={() => toggleTooltip(trans._id)}
                                >
                                  <i className="fa fa-edit "></i>
                                  {renderTooltip(trans._id, 'Edit')}
                                </Button>{" "}
                                <Button
                                  className="btn btn-sm btn-success tooltip-button"
                                  onClick={() => handleTreeClick(trans._id)}
                                  // onMouseEnter={() => toggleTooltip(trans._id)}
                                  // onMouseLeave={() => toggleTooltip(trans._id)}
                                >
                                  <i className="fa fa-tree "></i>
                                </Button>{" "}
                                <Button
                                  className="btn btn-sm btn-danger tooltip-button"
                                  onClick={() => deleterow(trans._id)}
                                  // onMouseEnter={() => toggleTooltip(`delete-${trans._id}`)}
                                  // onMouseLeave={() => toggleTooltip(`delete-${trans._id}`)}
                                >
                                  <i className="fa fa-trash"></i>
                                  {renderTooltip(`delete-${trans._id}`, 'Delete')}
                                </Button>{" "}
                              </div></td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>Loading . . . . . . .</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>


                </TabContent>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem disabled={currentPage <= 1}>
                        <PaginationLink onClick={handlePreviousClick} previous />
                      </PaginationItem>
                      {[...Array(last_page)].map((page, i) => (
                        <PaginationItem
                          active={i + 1 === currentPage}
                          key={i + 1}
                        >
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, i + 1)}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage >= last_page}>
                        <PaginationLink onClick={handleNextClick} next />
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
      <Modal className="taskdetailmodal" isOpen={setcreateformModalOpen} scrollable={true} style={{ width: "60%" }}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            Create Vehicle
          </h5>
          <button aria-label="Close" className=" close" type="button" onClick={() => setcreateFormModalOpen(!setcreateformModalOpen)}>
            <span aria-hidden={true}>×</span>
            <div>

            </div>
          </button>
        </div>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Name
                </label>
                <Input
                  className="form-control-alternative"
                  defaultValue={inputFields.name}
                  id="input-name"
                  placeholder="Gotra Name"
                  name="name"
                  type="text"
                  onChange={inputhandler}

                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Image
                </label>
                <Input
                  className="form-control-alternative"
                  defaultValue={inputFields.image}
                  id="input-name"
                  placeholder="Image"
                  name="image"
                  type="text"
                  onChange={inputhandler}

                />
              </FormGroup>
            </Col>

            <Col lg={12}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Description
                </label>
                <Input
                  className="form-control-alternative"
                  defaultValue={inputFields.description}
                  id="input-name"
                  placeholder="Description"
                  name="description"
                  type="textarea"
                  onChange={inputhandler}

                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <br></br>
            <Button color='success' size="extra-small" onClick={onRespondClick.bind(this)}>
              Create Gotra
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Index;