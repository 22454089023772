import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { userService } from '../../services/authentication';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);
  const [phases, setPhases] = useState([]);
  const history = useHistory();

  const fetchData = () => {
    // Uncomment when API calls are implemented
    // userService.fetchRoles().then((response) => {
    //   if (response.status === 200) {
    //     setRoles(response.data.roles);
    //   } else {
    //     toast.error('Something went wrong!');
    //   }
    // });

    // userService.fetchPhases().then((response) => {
    //   if (response.status === 200) {
    //     setPhases(response.data.phases);
    //   } else {
    //     toast.error('Something went wrong!');
    //   }
    // });
  };

  useEffect(() => {
    // fetchData()
  }, []);

  const inputhandler = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signup = (e) => {
    e.preventDefault();
    const { name, email, password, confirmpassword, roles, phase_id } = inputFields;

    userService.register(name, email, password, confirmpassword, roles, phase_id).then((response) => {
      if (response.status === 200) {
        document.getElementById("register-form").reset();
        toast.success(response.data.message);
        swal({
          title: "Sign up!",
          text: response.data.message + ' .Please login to continue',
          icon: "success",
          button: "OK",
        });
      } else if (response.status === 201) {
        if (response.data.email) {
          toast.error(response.data.email[0]);
        }
        if (response.data.password) {
          toast.error(response.data.password[0]);
        }
        if (response.data.roles) {
          toast.error(response.data.roles[0]);
        }
      }
    });
  };

  const inputFieldsInitialState = {
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
    roles: "",
    phase_id: "",
  };

  const [inputFields, setInputFields] = useState(inputFieldsInitialState);

  return (
    <>
      <ToastContainer />
      <Col lg="6" md="8" sm="10" xs="12" className="mx-auto my-auto mt-5">
        {/* <ToastContainer /> */}
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h2>Sign up</h2>
            </div>
            {/* <Form role="form" id="register-form" onSubmit={signup}> */}
              <Form role="form" id="register-form" onSubmit={signup}>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Name"
                      type="text"
                      name="name"
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      name="email"
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      autoComplete="new-password"
                      onChange={inputhandler}
                      required
                    />
                    <InputGroupAddon addonType="append">
                      <Button color="secondary" onClick={togglePasswordVisibility}>
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>

                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      autoComplete="confirmpassword"
                      name="confirmpassword"
                      onChange={inputhandler}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <select class="form-control" name="roles" id="role_id" onChange={inputhandler} required>
                      <option value=""> Select Role</option>
                      {roles.map(role => (
                        <option
                          key={role.id}
                          value={role.id}
                        >
                          {role.name}
                        </option>
                      ))}
                    </select>
                    <br />
                    <select class="form-control" name="phase_id" id="phase_id" onChange={inputhandler} >
                      <option value=""> Select Phase</option>
                      {phases.map(phase => (
                        <option
                          key={phase.id}
                          value={phase.id}
                        >
                          {phase.name}
                        </option>
                      ))}
                    </select>
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit">
                    Create account
                  </Button>
                </div>
              </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="12" className="text-center">
            <p className="text-light" onClick={() => history.push('login')}>
              <a
                className="text-light"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <small>Sign in</small>
              </a>
            </p>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Register;
