
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const userService = {
  getWithPagination,
  deleteById,
  getById,
  updateById,
};

function getWithPagination(page,query){
  let filters = '', searchFilters = [];
  if(query){
    Object.keys(query).map(item => {
      searchFilters.push(`${item}=${query[item]}`)
    })
    if(searchFilters.length > 0){
      filters = `&${searchFilters.join("&")}`;
    }
  }
  return axios({
      method: "get",
      url: `${apiUrl}/user/get_with_paginate?limit=10&currentPage=${page}${filters}`,
      headers: {Authorization: `${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getById(id){
  
  return axios({
      method: "get",
      url: `${apiUrl}/user/${id}`,
      headers: {Authorization: `${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function deleteById(id){
  var datasend = JSON.stringify({
    "ids": [id]
  });
  return axios({
      method: "post",
      url: `${apiUrl}/user/delete`,
      data: datasend,
      headers: {Authorization: `${authtoken}`,'Content-Type': 'application/json' },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return response;
    }); 
}

function updateById(data){
  let transformData = {
      name: "",
      email: "",
      phone: "",
      gender: "male",
      gotra:{},
      father:{},
      partner:{},
      about:"",
      profession:"",
      education:"",
      birthDate:""
  }
  var datasend = {};
  Object.keys(transformData).map(item => {
    if(data[item]) datasend[item] = data[item]
  });
  
  return axios({
      method: "put",
      url: `${apiUrl}/user/update/${data._id}`,
      data: JSON.stringify(datasend),
      headers: {Authorization: `${authtoken}`,'Content-Type': 'application/json' },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return response;
    }); 
}