import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import {
  useHistory
} from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TabContent,
  TabPane,
  Modal,
  ModalBody
} from "reactstrap";
import ReactQuill from 'react-quill';
const axios = require("axios");
import { authtoken } from "global.js";
import { apiUrl } from "../../constant";
import { toast } from "react-toastify";
// core components
import { userService } from "../../services/userService";
import ImagePreview from "../../components/ImagePreview";
import { gotraService } from "../../services/gotraService";

const User = (props) => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [createuserModalOpen, setcreateUserModalOpen] = useState(false);
  const get_user_data = async (pageNumber = 1, company = '') => {
    userService.getWithPagination(pageNumber, company).then((response) => {
      setUsers(response.data.data.data);
      setCurrentPage(response.data.data.currentPage);
      setLastPageData(response.data.data.totalPage);
    });
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_user_data(index);
  };

  const handlePreviousClick = () => {
    get_user_data(prev);
  };

  const handleNextClick = () => {
    get_user_data(next);
  };

  const [gotraList, setGotraList] = useState([])
  function gotralist() {
    gotraService.getWithPagination(1).then((response) => {
      if (response?.status === 200) {
        setGotraList(response?.data?.data?.data)
      } else {
        toast.error('Something went wrong!');
      }
    });
  }

  const [fatherList, setFatherList] = useState([])
  function fatherlist() {
    userService.getWithPagination(1).then((response) => {
      if (response?.status === 200) {
        setFatherList(response?.data?.data?.data)
      } else {
        toast.error('Something went wrong!');
      }
    });
  }

  const [partnerList, setPartnerList] = useState([])
  function partnerlist() {
    userService.getWithPagination(1).then((response) => {
      if (response?.status === 200) {
        setPartnerList(response?.data?.data?.data)
      } else {
        toast.error('Something went wrong!');
      }
    });
  }

  useEffect(() => {
    get_user_data()
    gotralist()
    fatherlist()
    partnerlist()
  }, []);


  const initialStateData = {
    name: "",
    email: "",
    phone: "",
    gender: "male",
    gotra:{},
    father:{},
    partner:{},
    about:"",
    profession:"",
    education:"",
    birthDate:""
  };

  const [inputFields, setInputfields] = useState(initialStateData);
  function onRespondClick() {
    let newData = {};
    Object.keys(inputFields).map(item => {
      if(inputFields[item] != undefined && inputFields[item] && inputFields[item] != {}){
        newData[item] = inputFields[item]
      }
    })
    console.log("newData", newData)
    axios({
      method: "post",
      url: `${apiUrl}/user/create`,
      data: newData,
      headers: { Authorization: `${authtoken}` },
    })
      .then(
        function (response) {
          console.log(response)
          if (response.status == 200) {
            setInputfields(initialStateData)
            setcreateUserModalOpen(false)
            toast.success(response.data.message);
            get_user_data()
          } else {
            toast.error(response.data.message);
          }
        },
        (error) => {
          return error.response;
        }
      )
      .catch(function (error) { });
  }
  function inputhandler(e) {
    let field = e.target.name;
    let value = e.target.value;
    setInputfields({ ...inputFields, [field]: ['gotra','father','partner'].includes(field) ? JSON.parse(value) : value });
  }

  const deleterow = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((deletdata) => {
        if (deletdata) {
          userService.deleteById(e).then((response) => {
            if (response.data.code == 200) {
              const newList = users.filter((item) => item._id !== e);
              setUsers(newList);
              toast.success('Deleted Successfully');
            } else {
              toast.error('Failed');
            }
          });
        }
      });

  };
  const handleclick = (e) => {
    history.push({
      pathname: 'edituser/' + e,
    });
  }
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}

      <Container className="mt--7" fluid>

        <Row>

          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <div className="align-items-center row">
                   <Col lg="8" md="8" sm="8">
                    <h3 className="mb-0">User</h3>
                  </Col>
                  <div className="text-right col-4"> <button className="btn btn-sm" onClick={() => setcreateUserModalOpen(true)}  style={{backgroundColor:"#5e72e4" , color:"#fff  "}}> Create User</button></div>
                </div>
              </CardHeader>
              <CardBody>

                <TabContent activeTab={"tabs1"}>
                  <TabPane tabId={"tabs1"}>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Father</th>
                          <th scope="col">Gotra</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.length > 0 && users ? (
                          users.map((trans, i) => (
                            <tr key={i}  >
                              <th> <ImagePreview value={trans?.image}/></th>
                              <th>{trans.name}</th>
                              <td>{trans.email}</td>
                              <td>{trans?.father?.name}</td>
                              <td>{trans?.gotra?.name}</td>
                              <td>{trans?.gender}</td>
                              <td>{trans.phone}</td>

                              <td><>
                                <Button style={{ padding: '5px 8px', fontSize: '12px', width: "30px", height: "30px" }}
                                  className="btn btn-success" onClick={handleclick.bind(this, trans._id)}>
                                  <i className="fa fa-edit"></i>
                                </Button>
                                <Button style={{ padding: '5px 8px', fontSize: '12px', width: "30px", height: "30px" }}
                                  className="btn btn-danger" onClick={deleterow.bind(this, trans._id)}>
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </></td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>Loading.......</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>


                </TabContent>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem disabled={currentPage <= 1}>
                        <PaginationLink onClick={handlePreviousClick} previous />
                      </PaginationItem>
                      {[...Array(last_page)].map((page, i) => (
                        <PaginationItem
                          active={i + 1 === currentPage}
                          key={i + 1}
                        >
                          <PaginationLink 
                            onClick={(e) => handlePageClick(e, i + 1)}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage >= last_page}>
                        <PaginationLink onClick={handleNextClick} next />
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
      <Modal className="taskdetailmodal" isOpen={createuserModalOpen} scrollable={true} style={{ width: "70%" }}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            Create User
          </h5>
          <button aria-label="Close" className=" close" type="button" onClick={() => setcreateUserModalOpen(!createuserModalOpen)}>
            <span aria-hidden={true}>×</span>
            <div>

            </div>
          </button>
        </div>
        <ModalBody>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
                <label className="form-control-label">Name</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={inputFields.name}
                  onChange={e => inputhandler(e)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
            <FormGroup>
                <label for="gender" className="form-control-label">
                  Gender
                </label>
                <Input
                  className="form-control-alternative"
                  type="select"
                  name="gender"
                  id="gender"
                  defaultValue={inputFields.gender}
                  onChange={inputhandler}
                >
                  <option value="male" selected>Male</option>
                  <option value="female">Female</option>

                </Input>
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
              <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Gotra
                </label>
                <select className="form-control" name="gotra" onChange={(e) => { inputhandler(e) }} required >
                  <option value=""> Select Gotra</option>
                  {gotraList.map(res => (
                    <option
                      key={res._id}
                      value={JSON.stringify({gotraId: res._id, name: res.name})}
                    >
                      {res.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
              <label className="form-control-label"
                  htmlFor="input-fname"
                >
                  Date of birth
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Date of birth"
                  type="date"
                  name="birthDate"
                  value={
                    inputFields?.birthDate ?? ""
                  }
                  onChange={inputhandler}
                  required
                />
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
              <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Father
                </label>
                <select className="form-control" name="father" onChange={(e) => { inputhandler(e) }} required >
                  <option value=""> Select Father</option>
                  {fatherList.map(res => (
                    <option
                      key={res._id}
                      value={JSON.stringify({fatherId: res._id, name: res.name, uniqueId: res?.uniqueId})}
                    >
                      {res.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
              <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Partner
                </label>
                <select className="form-control" name="partner" onChange={(e) => { inputhandler(e) }} required >
                  <option value=""> Select Partner</option>
                  {partnerList.map(res => (
                    <option
                      key={res._id}
                      value={JSON.stringify({partnerId: res._id, name: res.name, uniqueId: res?.uniqueId})}
                    >
                      {res.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
                <label className="form-control-label">Education</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Education"
                  type="text"
                  name="education"
                  value={inputFields.education}
                  onChange={e => inputhandler(e)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
                <label className="form-control-label">Profession</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Profession"
                  type="text"
                  name="profession"
                  value={inputFields.profession}
                  onChange={e => inputhandler(e)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="12">
              <FormGroup>
                <label className="form-control-label">About</label>
                <Input
                  className="form-control-alternative"
                  placeholder="About"
                  type="textarea"
                  name="about"
                  value={inputFields.about}
                  onChange={e => inputhandler(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Button color='success' size="extra-small" onClick={onRespondClick.bind(this)}>
              Create User
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </>
  );
};

export default User;