import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from "react";
import { userService } from '../../services/userService';
import 'assets/css/custom.css';
import { toast } from 'react-toastify';
import { Formloader } from 'components/Loader/PageLoader.js';
const axios = require("axios");
import { useHistory } from "react-router-dom";
import { gotraService } from "../../services/gotraService";
import moment from "moment";


const Useredit = () => {
  let { userid } = useParams();
  const history = useHistory();
  const [inputFields, setInputfields] = useState({});

  function getuser() {
    userService.getById(userid).then((response) => {
      setInputfields(response.data.data);
    });
  }
  const [gotraList, setGotraList] = useState([])
  function gotralist() {
    gotraService.getWithPagination(1).then((response) => {
      if (response?.status === 200) {
        setGotraList(response?.data?.data?.data)
      } else {
        toast.error('Something went wrong!');
      }
    });
  }

  const [fatherList, setFatherList] = useState([])
  function fatherlist() {
    userService.getWithPagination(1).then((response) => {
      if (response?.status === 200) {
        setFatherList(response?.data?.data?.data)
      } else {
        toast.error('Something went wrong!');
      }
    });
  }

  const [partnerList, setPartnerList] = useState([])
  function partnerlist() {
    userService.getWithPagination(1).then((response) => {
      if (response?.status === 200) {
        setPartnerList(response?.data?.data?.data)
      } else {
        toast.error('Something went wrong!');
      }
    });
  }


  useEffect(() => {
    getuser();
    gotralist()
    fatherlist()
    partnerlist()
  }, []);

  const [formloader, setformloader] = useState(false);

  function inputhandler(e) {
    let field = e.target.name;
    let value = e.target.value;
    setInputfields({ ...inputFields, [field]: ['gotra','father','partner'].includes(field) ? JSON.parse(value) : value });
  }

  function profileupdate(e) {
    e.preventDefault();
    setformloader(true);
    console.log(inputFields)
    userService.updateById(inputFields).then((response) => {
      if (response?.data?.code == 200) {
        setformloader(false);
        toast.success('Updated Succesfully!');
        history.push({
          pathname:"/admin/user"
        })
      } else {
        setformloader(false);
        toast.error('Failed');
      }
    });
  }
  return (
    <>

      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="justify-content-md-center">

          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">

              <CardBody>

                <Form onSubmit={profileupdate}>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row className="justify-content-md-center">
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
                <label className="form-control-label">Name</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={inputFields.name}
                  onChange={e => inputhandler(e)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
            <FormGroup>
                <label for="gender" className="form-control-label">
                  Gender
                </label>
                <Input
                  className="form-control-alternative"
                  type="select"
                  name="gender"
                  id="gender"
                  defaultValue={inputFields.gender}
                  onChange={inputhandler}
                >
                  <option value="male" selected>Male</option>
                  <option value="female">Female</option>

                </Input>
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
              <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Gotra
                </label>
                <select className="form-control" name="gotra"  value={JSON.stringify(inputFields?.gotra)} onChange={(e) => { inputhandler(e) }} required >
                  <option value=""> Select Gotra</option>
                  {gotraList.map(res => (
                    <option
                      key={res._id}
                      value={JSON.stringify({gotraId: res._id, name: res.name})}
                    >
                      {res.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
              <label className="form-control-label"
                  htmlFor="input-fname"
                >
                  Date of birth
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Date of birth"
                  type="date"
                  name="birthDate"
                  value={ inputFields.birthDate ? moment(inputFields.birthDate).format("YYYY-MM-DD") : ""}
                  onChange={inputhandler}
                  required
                />
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
              <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Father
                </label>
                <select className="form-control" name="father" value={JSON.stringify(inputFields?.father)} onChange={(e) => { inputhandler(e) }} required >
                  <option value=""> Select Father</option>
                  {fatherList.map(res => (
                    <option
                      key={res._id}
                      value={JSON.stringify({fatherId: res._id, name: res.name, uniqueId: res?.uniqueId})}
                    >
                      {res.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
              <label
                  className="form-control-label"
                  htmlFor="input-fname"
                >
                  Partner
                </label>
                <select className="form-control" name="partner" value={JSON.stringify(inputFields?.partner)} onChange={(e) => { inputhandler(e) }} required >
                  <option value=""> Select Partner</option>
                  {partnerList.map(res => (
                    <option
                      key={res._id}
                      value={JSON.stringify({partnerId: res._id, name: res.name, uniqueId: res?.uniqueId})}
                    >
                      {res.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
                <label className="form-control-label">Education</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Education"
                  type="text"
                  name="education"
                  value={inputFields.education}
                  onChange={e => inputhandler(e)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
              <FormGroup>
                <label className="form-control-label">Profession</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Profession"
                  type="text"
                  name="profession"
                  value={inputFields.profession}
                  onChange={e => inputhandler(e)}
                />
              </FormGroup>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="12">
              <FormGroup>
                <label className="form-control-label">About</label>
                <Input
                  className="form-control-alternative"
                  placeholder="About"
                  type="textarea"
                  name="about"
                  value={inputFields.about}
                  onChange={e => inputhandler(e)}
                />
              </FormGroup>
            </Col>
          </Row>
                    <div className="text-center">
                      <button type="submit" className="btn btn-success mt-4" onClick={profileupdate} disabled={formloader}>
                        Update  {formloader ? (<Formloader height={20} color='#ffffff'></Formloader>) : (<></>)}
                      </button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Useredit;
