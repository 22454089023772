//admins
import Dashboard from "views/user/Dashboard.js";
import Uploads from "views/uploads/Index";
import User from "views/user/User.js";
import Useredit from "views/user/Useredit.js";
import Profile from "views/auth/Profile.js";
import Gotra from "views/gotra/index.js";
import GotraEdit from "views/gotra/edit.js";
import GotraTree from "views/gotra/tree.js";

var user_info = { rolename: 'default' };
if (user_info != null) {
  if (JSON.parse(localStorage.getItem('user-info')) != null) {
    user_info = JSON.parse(localStorage.getItem('user-info'));
  } else {
    user_info.rolename = 'default';
  }
}

// non visible routes
switch (user_info?.rolename ?? user_info?.role) {
  case 'admin':
    var adminroutes = [
      {
        path: "/index",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-th",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/user",
        name: "User",
        icon: "ni ni-user text-th",
        component: User,
        layout: "/admin",
      },
      {
        path: "/edituser/:userid",
        name: "Edit User",
        icon: "ni ni-user text-th",
        component: Useredit,
        layout: "/admin",
      },
      {
        path: "/gotra",
        name: "Gotra",
        icon: "ni ni-user text-th",
        component: Gotra,
        layout: "/admin",
      },
      {
        path: "/editgotra/:id",
        name: "Edit Gotra",
        icon: "ni ni-user text-th",
        component: GotraEdit,
        layout: "/admin",
      },
      {
        path: "/treegotra/:id",
        name: "Tree Gotra",
        icon: "ni ni-user text-th",
        component: GotraTree,
        layout: "/admin",
      },
      {
        path: "/user-profile",
        name: "User Profile",
        icon: "ni ni-settings-gear-65",
        component: Profile,
        layout: "/admin",
      }

    ];
    break;
  default:
    var adminroutes = [
      {
        path: "/index1",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-th",
        component: Dashboard,
        layout: "/admin",
      }
    ];

}

export default adminroutes;
