
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const gotraService = {
  getWithPagination,
  deleteById,
  getById,
  updateById,
  getTreeById
};

function getWithPagination(page,query){
  let filters = '', searchFilters = [];
  if(query){
    Object.keys(query).map(item => {
      searchFilters.push(`${item}=${query[item]}`)
    })
    if(searchFilters.length > 0){
      filters = `&${searchFilters.join("&")}`;
    }
  }
  return axios({
      method: "get",
      url: `${apiUrl}/gotra/get_with_paginate?limit=10&currentPage=${page}${filters}`,
      headers: {Authorization: `${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getById(id){
  
  return axios({
      method: "get",
      url: `${apiUrl}/gotra/${id}`,
      headers: {Authorization: `${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getTreeById(id){
  
  return axios({
      method: "get",
      url: `${apiUrl}/user/tree_by_gotra/${id}`,
      headers: {Authorization: `${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function deleteById(id){
  var datasend = JSON.stringify({
    "ids": [id]
  });
  return axios({
      method: "post",
      url: `${apiUrl}/gotra/delete`,
      data: datasend,
      headers: {Authorization: `${authtoken}`,'Content-Type': 'application/json' },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return response;
    }); 
}

function updateById(data){
  var datasend = JSON.stringify({
    "name": data.name,
    "description": data.description,
    "image" : data.image
  });
  return axios({
      method: "put",
      url: `${apiUrl}/gotra/update/${data._id}`,
      data: datasend,
      headers: {Authorization: `${authtoken}`,'Content-Type': 'application/json' },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return response;
    }); 
}