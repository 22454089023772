
import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const dashboardServices = {
  getDashboard
};

function getDashboard(){
  return axios({
      method: "get",
      url: `${apiUrl}/dashboard`,
      headers: {Authorization: `${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}