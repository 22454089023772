import Profile from "views/auth/Profile.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Forgotpassword from "views/auth/Forgotpassword";
import Resetpassword from "views/auth/Resetpassword";
import Dashboard from "views/user/Dashboard.js";
import User from "views/user/User.js";
import Gotra from "views/gotra/index.js";

var user_info = { rolename: 'default' };
if (user_info != null) {
  if (JSON.parse(localStorage.getItem('user-info')) != null) {
    user_info = JSON.parse(localStorage.getItem('user-info'));
  } else {
    user_info.rolename = 'default';
  }
}


switch (user_info?.rolename ?? user_info?.role) {
  case 'admin':
    var routes = [
      {
        path: "/index",
        name: "Dashboard",
        icon: "ni ni-ui-04 text-th",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/user",
        name: "User",
        icon: "fa fa-solid fa-user-secret",
        component: User,
        layout: "/admin",
      },
      {
        path: "/gotra",
        name: "Gotra",
        icon: "fa fa-solid fa-truck",
        component: Gotra,
        layout: "/admin",
      },
      {
        path: "/user-profile",
        name: "User Profile",
        icon: "ni ni-settings-gear-65",
        component: Profile,
        layout: "/admin",
      }

    ];
    break;
  default:
    var routes = [
      {
        path: "/index",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-th",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        icon: "ni ni-key-25 text-th",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        icon: "ni ni-circle-08 text-th",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/resetpassword",
        name: "Resetpassword",
        icon: "ni ni-circle-08 text-th",
        component: Resetpassword,
        layout: "/auth",
      },
      {
        path: "/forgotpassword",
        name: "Forgotpassword",
        icon: "ni ni-circle-08 text-th",
        component: Forgotpassword,
        layout: "/auth",
      }
    ];

}

export default routes;
