import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer className="py-6 mb-3">
        <Container>
          <Row className="align-items-center justify-content-md-between">
            <Col md="12">
              <div className="copyright text-center text-md-center text-muted">
                Design & Developed by{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://aaronsoftech.com/home"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Arambh Technologies.
                </a>{" "}
                © {new Date().getFullYear()}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
