import React, { useState } from 'react';

const ImagePreview = (props) => {
  return (
    <div>
      <img src={props.value} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />
    </div>
  );
};

export default ImagePreview;