import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from "react";
import { gotraService } from '../../services/gotraService';
import 'assets/css/custom.css';
import { toast } from 'react-toastify';
import { Formloader } from 'components/Loader/PageLoader.js';
const axios = require("axios");
import moment from "moment";
import { useHistory } from "react-router-dom";
const edit = () => {
  let { id } = useParams();
  const history = useHistory();
  const [inputFields, setInputfields] = useState({});
  useEffect(() => {
    getuser();
  }, []);

  function getuser() {
    gotraService.getById(id).then((response) => {
      setInputfields(response.data.data);

    });
  }

  const [formloader, setformloader] = useState(false);

  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

  function profileupdate(e) {
    e.preventDefault();
    setformloader(true);
    console.log(inputFields)
    gotraService.updateById(inputFields).then((response) => {
      if (response.data.code == 200) {
        setformloader(false);
        toast.success('Updated Succesfully!');
        history.push({
          pathname:"/admin/gotra",
        })
      } else {
        setformloader(false);
        toast.error('Failed');
      }
    });
  }
  return (
    <>

      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="justify-content-md-center">

          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">

              <CardBody>

                <Form onSubmit={profileupdate}>
                  <h6 className="heading-small text-muted mb-4">
                    Gotra Detail
                  </h6>
                  <div className="pl-lg-4">
                    <Row className="justify-content-md-center">
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-fname"
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={inputFields?.name}
                            id="input-name"
                            placeholder="Name"
                            name="name"
                            type="text"
                            onChange={inputhandler}

                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-fname"
                          >
                            Image
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={inputFields?.image}
                            id="input-name"
                            placeholder="Image"
                            name="name"
                            type="text"
                            onChange={inputhandler}

                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-fname"
                          >
                            Description
                          </label>

                          <Input
                            className="form-control-alternative"
                            defaultValue={inputFields?.description}
                            id="input-name"
                            placeholder="Description"
                            name="number"
                            type="textarea"
                            onChange={inputhandler}

                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <button type="submit" className="btn btn-success mt-4" onClick={profileupdate} disabled={formloader}>
                        Update  {formloader ? (<Formloader height={20} color='#ffffff'></Formloader>) : (<></>)}
                      </button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default edit;
