// export const apiUrl = '';
// export const domainUrl = '';

// export const apiUrl = 'https://api.shyamlogistics.co.in/api/admin';
// export const domainUrl = 'https://api.shyamlogistics.co.in/';


export const apiUrl = 'https://vanshavali.arambh.tech/api';
export const domainUrl = 'https://vanshavali.arambh.tech/';

// export const apiUrl = 'http://localhost:8080/api';
// export const domainUrl = 'http://localhost:8080/';



