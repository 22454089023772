import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect, useRef } from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { gotraService } from '../../services/gotraService';
import 'assets/css/custom.css';
// import { Tree, TreeNode } from 'react-organizational-chart';
import { Tree, TreeNode } from 'react-d3-tree';
const tree = () => {
  const containerRef = useRef(null);
  let { id } = useParams();
  const [inputFields, setInputfields] = useState({});
  const [TreeData, setTreeData] = useState([]);
  const [scale, setScale] = useState(1);

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => (prevScale > 0.1 ? prevScale - 0.1 : 0.1));
  };

  useEffect(() => {
    getuser();
  }, []);

  function getuser() {
    gotraService.getTreeById(id).then((response) => {
      console.log("response.data.data", response.data.data)
      const _data = response.data.data?.children;
      const rootParent = response.data.data?.parent;
      // function buildHierarchy(parentId) {
      //   const children = _data.filter(item => item?.father?.fatherId === parentId)
      //     .map(item => ({ ...item, children: buildHierarchy(item._id) }));
      //   return children.length > 0 ? children : null;
      // }
    
      // const result = buildHierarchy(rootParent?._id);
      // setInputfields({
      //   ...rootParent,
      //   children:result
      // });
      const treeData = createTreeData([..._data, rootParent]);
      setTreeData(treeData);
    });
  }

  // const data = {
  //   name: 'Root Node',
  //   children: [
  //     {
  //       name: 'Child Node 1',
  //       children: [
  //         {
  //           name: 'Child Node 1.1',
  //         },
  //         {
  //           name: 'Child Node 1.2',
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Child Node 2',
  //       children: [
  //         {
  //           name: 'Child Node 2.1',
  //         },
  //         {
  //           name: 'Child Node 2.2',
  //         },
  //       ],
  //     },
  //   ],
  // };

  // const renderTreeNodes = (nodes) => (
  //   nodes.map((node, index) => (
  //     <TreeNode key={index} label={<span style={{ textTransform: 'capitalize', maxWidth: '100px'  }}>{node.name}</span>}>
  //       {node.children && renderTreeNodes(node.children)}
  //     </TreeNode>
  //   ))
  // );

  function createTreeData(list) {
    const treeData = [];
    const map = new Map();
    list.forEach(item => {
      let newData = item;
      if(item?.father?.fatherId) newData['fatherId'] = item?.father?.fatherId;
      map.set(item._id, { ...newData, children: [] });
    });
    map.forEach(node => {
      if (node.fatherId && map.has(node.fatherId)) {
        map.get(node.fatherId).children.push(node);
      } else {
        treeData.push(node);
      }
    });
    return treeData;
  }

  const downloadPDF = () => {
    console.log("containerRef", containerRef)
    if (!containerRef.current) return;

    // Capture the content as an image using html2canvas
    html2canvas(containerRef.current).then((canvas) => {
      // Convert the image to a PDF using jspdf
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust dimensions as needed
      pdf.save('tree_structure.pdf');
    });
  };
  
  const containerStyles = {
    width: '100%',
    height: '500px', // Set the height based on your requirement
    border: '1px solid #ccc',
  };
  return (
    <>

      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="justify-content-md-center">

          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardBody>
              <div>
              <Button onClick={handleZoomIn} color="success">
                Zoom In
              </Button>
              <Button onClick={handleZoomOut} color="danger">
                Zoom Out
              </Button>
              <Button onClick={downloadPDF} color="info">
                Print
              </Button>
            </div>
                  <h6 className="heading-small text-muted mb-4">
                    Family Tree
                  </h6>
                  {/* <div className="pl-lg-4" style={{ maxWidth: '100%', maxHeight: '100vh', overflow: 'auto' }}>
                      <div id="treeWrapper" ref={containerRef} className="Tree" style={{ width: '50em', height: '33em', transform: `scale(${scale})`, transformOrigin: 'top left'  }}>
                      {inputFields.name ? <Tree label={<span style={{"textTransform": "capitalize", maxWidth: '100px' }}>{inputFields.name}</span>}>
                    { renderTreeNodes(inputFields.children)}
                  </Tree> : ''}
                    </div>
                  </div> */}
                  <div style={containerStyles} id="treeWrapper" ref={containerRef}>
                  {TreeData.length > 0 ?<Tree
                    data={TreeData}
                    orientation="vertical"
                    translate={{ x: 200, y: 50 }} // Adjust the x and y coordinates as needed
                  />:""}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default tree;
