import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { userService } from "../../services/authentication";
import { ToastContainer, toast } from "react-toastify";
import { UncontrolledAlert } from "reactstrap"; 

const Login = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [inputFieldEmail, setInputemail] = useState({
    email: "",
  });

  const [inputFieldpass, setInputpassword] = useState({
    password: "",
  });

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <UncontrolledAlert className="alert-danger" fade={false}>
      {error}
    </UncontrolledAlert>
  ) : (
    ""
  );

  const authenticate = (e) => {
    e.preventDefault();
    var email = inputFieldEmail.email;
    var pass = inputFieldpass.password;
    if (email == "" || pass == "") {
      toast.error("All fields are Required !");
      return false;
    }
    var bodyFormData = new FormData();
    userService.login(inputFieldEmail.email, inputFieldpass.password).then(
      (response) => {
        if (response?.status == 200) {
          toast.success(response.data.message);
        } else {
          toast.error("Invalid email or password!!!");
        }
      }
    );
    return;
  };

  const inputsHandleremail = (e) => {
    setInputemail({ [e.target.name]: e.target.value });
  };

  const inputsHandlerpass = (e) => {
    setInputpassword({ [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Col lg="5" md="8" sm="10" xs="12" className="mx-auto">
      <Card className="bg-secondary shadow border-0">
        <ToastContainer />
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-2">
            <br /> <br />
            <h1>Admin</h1>
            <br />
          </div>
          {errorDiv}
          <Form id="login-form" role="form" onSubmit={(e) => authenticate(e)}>
            <FormGroup className="mb-4">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  name="email"
                  autoComplete="new-email"
                  onChange={inputsHandleremail}
                  required
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  autoComplete="new-password"
                  onChange={inputsHandlerpass}
                  required
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={togglePasswordVisibility}>
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <Row className="mt-3">
                <Col className="text-right">
                  <a
                    className="text-light"
                    href="/auth/register"
                    onClick={(e) => history.push("forgotpassword")}
                  >
                    <small>Forgot password ? </small>
                  </a>
                </Col>
              </Row>
            </FormGroup>
            <div className="text-center">
              <Button className="my-2 col-12" color="primary" type="submit">
                <span>Sign in</span>
              </Button>
              {/* <a
                className="text-light"
                href="/auth/register"
                onClick={(e) => history.push("register")}
              >
                <small>Create new account</small>
              </a> */}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
